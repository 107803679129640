import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
import PagePromo from "../../components/PagePromo/PagePromo"
import ProposalCTA from "../../components/ProposalCTA/ProposalCTA"
import Badge from "../../components/Badge/Badge"
import LexiconCard from "../../components/LexiconCard"
import SEO from "../../components/seo"
import { Target, UserCheck, Tool, Compass } from "react-feather"

import styles from "./product-lexicon.module.css"
import toolkitStyles from "./toolkit.module.css"

export default ({ data }) => {
  const allTerms = data.allAirtable.edges

  let items = {}

  const details = [
    {
      color: "Blue",
      colorHex: "#23c1e3",
      icon: <Target />,
    },
    {
      color: "Red",
      colorHex: "#e0426b",
      icon: <UserCheck />,
    },
    {
      color: "Orange",
      colorHex: "#f48737",
      icon: <Tool />,
    },
    {
      color: "Green",
      colorHex: "#1ad39d",
      icon: <Compass />,
    },
    {
      color: "Purple",
      colorHex: "#a51cd2",
      icon: <Compass />,
    },
  ] // Color order for the titles

  allTerms.forEach((term, i) => {
    if (!term.node.data.hide && !!term.node.data.category) {
      // Easily allow us to hide items
      items[term.node.data.category] = [].concat(
        items[term.node.data.category] || [],
        [term]
      )
    }
  })

  return (
    <Layout>
      <SEO title="Our Digital jargon-buster: Product Lexicon" />
      <div className={toolkitStyles.introDetails}>
        <div className={toolkitStyles.introDetails1} />
      </div>
      <div className={toolkitStyles.container}>
        <div className={toolkitStyles.hero}>
          <h2 className={toolkitStyles.breadcrumbs}>
            <Link to="/toolkit">TOOLKIT</Link>
            <span>/</span>PRODUCT LEXICON
          </h2>
          <h3 className={toolkitStyles.subtitle}>Digital jargon-buster</h3>
          <p className={toolkitStyles.small_text}>
            Our way of keeping you in the loop when it comes to industry terms
            and chat. We've put together a lexicon of key words and phrases
            commonly used by designers and developers to get you started.
          </p>
        </div>

        <div className={styles.mainContent}>
          {Object.keys(items).map((itemTitle, index) => (
            <div className={styles.uiTerms} key={itemTitle}>
              <Badge title={itemTitle} color={details[index].color} />
              <div>
                {items[itemTitle].map(
                  ({
                    node: {
                      data: { name, description, link },
                      id,
                    },
                  }) => {
                    return (
                      <LexiconCard
                        color={details[index].colorHex}
                        icon={details[index].icon}
                        name={name}
                        description={description}
                        link={link}
                        key={id}
                      />
                    )
                  }
                )}
              </div>
            </div>
          ))}
        </div>

        <div className={styles.footerContent}>
          <PagePromo
            title="Curated Tools"
            text="A collection of the best practices and tools, battle tested by us."
            link="/toolkit/curated"
            image="/tools/toolkit_w_background.png"
          />
          <ProposalCTA
            title="Creative product design and ideation to make your ideas a reality"
            text="Get specialised ideation support, design & development to make your ideas come to life."
            link="/contact"
            linkText="👋 Get in touch"
          />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MyQuery {
    allAirtable(sort: { fields: data___order_id }) {
      edges {
        node {
          data {
            category
            description
            link
            name
            order_id
          }
        }
      }
    }
  }
`
