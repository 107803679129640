import React from "react"

import styles from "./LexiconCard.module.css"

const LexiconCard = props => {
  const { icon, name, description, link, id, color } = props

  return (
    <div className={styles.container} key={id}>
      <div>
        <span style={{ stroke: color }}>{icon}</span>
        <h4>{name}</h4>
      </div>
      <p
        dangerouslySetInnerHTML={{
          __html: description.replace("\n", "</br></br>"),
        }}
      />
      {!!link && <a href={link}>Learn more</a>}
    </div>
  )
}

export default LexiconCard
